import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Language from "../components/language"
import Image from "../components/image"

const IndexPage = () => {
  return (
    <Layout>
    <SEO title="Home" />
    <div className="comingSoon">
        <Image className="test" />
      <h1 className="comingSoon-title">
        <FormattedMessage id="pages.comingSoon.title" />
      </h1>
      <div className="comingSoon-subtitles">
        <p className="comingSoon-subtitle"><FormattedMessage id="pages.comingSoon.email" /></p>
        <p className="comingSoon-subtitle"><FormattedMessage id="pages.comingSoon.phoneNumber" /></p>
      </div>
      <div className="comingSoon-offices">
        <h1 className="comingSoon-offices-title"><FormattedMessage id="pages.comingSoon.ourOfficesTitle" /></h1>
        <div className="comingSoon-offices-section">
          <div className="comingSoon-office">
            <p className="comingSoon-office-title"><FormattedMessage id="pages.comingSoon.headOffice"/></p>
            <p><FormattedMessage id="pages.comingSoon.headOffice1.address" /></p>
          </div>
          <div className="comingSoon-office">
            <p className="comingSoon-office-title"><FormattedMessage id="pages.comingSoon.satelliteOffice"/></p>
            <p><FormattedMessage id="pages.comingSoon.satelliteOffice1.address" /></p>
          </div>
          <div className="comingSoon-office">
            <p className="comingSoon-office-title"><FormattedMessage id="pages.comingSoon.satelliteOffice"/></p>
            <p><FormattedMessage id="pages.comingSoon.satelliteOffice2.address" /></p>
          </div>
      </div>
      </div>
        <Language />
    </div>
    </Layout>
  )
}

export default IndexPage
