import React from 'react'
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

import "../css/language.css"

const languageName = {
    en: "English",
    fr: "French"
}

const Language = () => {
    return (
      <div className="language">
          <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            languages.map(language => (
              <button
                className="language-btn"
                key={language}
                onClick={() => changeLocale(language)}
                style={{
                  color: currentLocale === language ? `white` : `black`,
                  margin: 10,
                  cursor: `pointer`,
                }}
              >
                {languageName[language]}
              </button>
            ))
          }
        </IntlContextConsumer>
      </div>
    )
}

export default Language